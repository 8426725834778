import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 293.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,293.000000) scale(0.100000,-0.100000)">

 <path d="M2217 2403 c-133 -118 -177 -153 -191 -153 -8 0 -30 9 -48 19 -18 11
-76 34 -128 52 -88 30 -107 33 -256 37 -151 4 -169 2 -265 -22 -119 -31 -237
-84 -328 -149 -76 -54 -221 -195 -221 -215 0 -18 13 -8 85 64 130 132 290 199
496 211 69 4 130 1 175 -7 70 -13 260 -75 280 -91 6 -5 14 -6 17 -2 4 3 7 1 7
-5 0 -7 8 -12 18 -12 14 0 12 4 -8 19 -13 11 -28 17 -32 15 -4 -3 -8 2 -8 10
0 8 -8 15 -17 15 -36 1 -83 14 -78 21 3 5 0 12 -6 16 -8 4 -9 3 -5 -4 4 -7 1
-12 -8 -12 -9 0 -16 8 -16 18 0 12 -3 13 -8 5 -8 -13 -52 -7 -52 7 0 5 -8 6
-18 3 -10 -2 -31 1 -47 7 -51 19 -294 26 -329 9 -17 -8 -16 -6 3 9 31 25 90
44 201 64 65 11 320 4 347 -11 29 -16 127 -45 142 -42 7 1 10 -2 7 -8 -4 -6 5
-13 21 -17 15 -4 33 -11 39 -15 18 -14 67 -6 101 17 18 12 29 24 26 28 -4 3 1
6 9 6 9 0 19 7 22 16 4 9 9 14 13 10 3 -3 12 3 20 14 8 11 20 20 27 20 15 0
29 -37 16 -45 -6 -4 -13 -32 -36 -149 -3 -19 31 -76 46 -76 15 0 130 -72 130
-81 0 -13 -16 -17 -95 -23 -106 -9 -109 -9 -127 -33 -10 -11 -25 -50 -34 -85
-8 -34 -22 -76 -29 -93 l-14 -30 -16 38 c-46 111 -66 148 -74 142 -9 -5 -6
-27 8 -50 3 -5 24 -59 46 -119 22 -60 45 -111 51 -113 6 -2 15 22 22 54 31
157 50 245 56 250 8 8 273 22 296 16 32 -9 32 17 0 35 -17 9 -80 47 -140 84
-60 36 -112 63 -116 60 -5 -4 -3 0 3 8 6 8 18 44 28 80 9 36 26 100 37 143 12
42 18 80 15 83 -3 3 -30 -16 -60 -43z"/>
<path d="M2083 2164 l-43 -35 -45 31 c-25 17 -45 26 -45 20 0 -14 39 -90 46
-90 2 0 0 9 -6 19 -17 33 -11 41 20 23 24 -14 33 -15 50 -4 28 17 30 16 30
-17 0 -20 8 -34 25 -45 14 -9 24 -20 21 -24 -3 -4 9 -9 25 -10 16 -1 29 0 29
2 0 2 -20 16 -45 30 -25 15 -45 28 -45 29 0 1 7 24 15 51 8 27 14 51 12 52 -1
1 -21 -13 -44 -32z"/>
<path d="M1205 2166 c-93 -24 -233 -87 -295 -133 -19 -14 -69 -61 -112 -103
-59 -60 -85 -97 -117 -161 -61 -128 -75 -198 -75 -374 1 -174 20 -265 84 -403
122 -260 336 -436 645 -529 48 -14 95 -18 230 -17 147 0 181 3 250 22 92 27
179 63 248 105 74 45 47 44 -40 0 -73 -38 -148 -67 -204 -79 -25 -5 -57 -9
-171 -19 -37 -3 -76 -8 -85 -11 -15 -5 -16 -4 -3 6 12 9 6 10 -28 6 -55 -8
-178 22 -278 68 -233 108 -403 313 -478 576 -64 222 -39 434 76 652 55 105
184 243 279 299 81 49 231 99 293 99 25 0 48 5 51 10 13 20 -175 10 -270 -14z
m-185 -120 c0 -3 -17 -22 -37 -43 -137 -140 -223 -306 -263 -511 -21 -105 -8
-278 30 -392 34 -104 98 -240 118 -253 7 -5 10 -12 8 -16 -3 -4 15 -28 38 -54
24 -25 42 -48 39 -50 -2 -2 14 -16 36 -31 22 -15 42 -35 45 -43 4 -8 13 -13
21 -10 8 4 16 -2 20 -12 3 -11 16 -21 28 -25 17 -4 19 -8 9 -18 -8 -8 -15 -9
-20 -3 -8 12 -83 61 -95 63 -4 1 -30 24 -57 52 -28 28 -50 48 -50 43 0 -4 -8
7 -16 24 -9 18 -20 30 -24 28 -5 -3 -12 4 -15 16 -4 11 -19 35 -35 52 -30 35
-78 124 -109 204 -41 106 -53 173 -53 313 0 149 18 258 54 331 12 24 22 46 23
49 1 16 60 103 88 131 103 102 217 183 217 155z"/>
<path d="M1827 2066 c-14 -13 -28 -22 -30 -20 -2 2 -14 -8 -28 -23 -13 -14
-18 -23 -11 -19 6 4 12 2 12 -4 0 -6 11 1 25 16 14 15 25 31 25 36 0 5 4 7 8
4 4 -2 19 4 32 15 18 13 21 19 9 19 -9 0 -28 -11 -42 -24z"/>
<path d="M1719 1984 c-8 -9 -31 -27 -51 -40 -49 -31 -51 -57 -5 -50 17 3 68 8
112 11 44 3 104 8 134 12 50 5 52 7 31 18 -19 10 -30 10 -57 -1 -18 -8 -45
-14 -61 -15 -15 0 -61 -2 -102 -5 l-75 -6 30 26 c17 14 36 26 43 26 7 0 11 4
8 9 -4 5 0 11 6 14 7 2 10 7 7 11 -3 4 -12 -1 -20 -10z"/>
<path d="M1786 1840 c-76 -23 -146 -100 -166 -182 -16 -65 -2 -96 71 -157 35
-30 72 -65 82 -78 25 -31 32 -83 14 -107 -13 -18 -19 -19 -54 -8 -66 20 -88
59 -68 120 18 54 -62 8 -94 -55 -15 -30 -14 -79 4 -112 16 -31 69 -61 109 -61
68 0 156 66 192 145 45 96 25 154 -83 244 -34 28 -67 60 -73 72 -14 25 -4 75
16 83 33 13 91 -30 80 -60 -10 -27 4 -35 35 -18 36 18 69 69 69 105 0 51 -72
88 -134 69z m77 -44 c18 -14 21 -33 11 -60 -9 -23 -22 -20 -61 14 -18 17 -39
30 -46 30 -7 0 -3 7 7 15 24 18 65 19 89 1z m-178 -136 c6 -20 35 -51 122
-131 54 -50 65 -81 51 -137 -11 -41 -24 -41 -33 0 -5 22 -34 57 -91 113 -70
68 -84 87 -84 113 0 56 22 83 35 42z m30 -381 c27 -10 32 -16 23 -26 -14 -17
-88 -16 -111 1 -25 19 -39 73 -26 98 10 18 13 17 45 -21 19 -22 50 -46 69 -52z"/>
<path d="M1373 1820 c-33 -20 -53 -48 -53 -75 0 -11 6 -15 18 -11 10 4 40 -3
66 -15 37 -16 46 -24 41 -37 -19 -47 -26 -86 -31 -182 -5 -108 -20 -186 -36
-196 -5 -3 -20 2 -35 11 -22 15 -24 22 -19 56 5 34 4 39 -13 39 -10 0 -35 -18
-55 -40 -30 -33 -36 -48 -36 -84 0 -86 75 -109 163 -48 85 58 121 148 132 327
7 112 18 149 56 197 25 30 18 51 -15 42 -14 -3 -40 3 -64 15 -51 26 -78 26
-119 1z m112 -34 c41 -17 41 -20 15 -77 -11 -24 -20 -37 -20 -28 0 17 -50 69
-67 69 -20 0 -34 25 -21 38 16 16 51 15 93 -2z m-7 -238 c-5 -145 -27 -195
-28 -65 0 80 15 177 27 177 3 0 4 -51 1 -112z m-137 -295 c-21 -16 -64 -16
-79 -1 -15 15 -15 43 -2 69 10 19 13 18 54 -18 38 -33 42 -39 27 -50z"/>
<path d="M2381 1628 c-1 -4 6 -40 15 -80 21 -97 21 -274 0 -362 -43 -181 -138
-338 -269 -443 -148 -119 -325 -181 -522 -184 -49 -1 -81 -5 -71 -9 18 -6 18
-7 2 -13 -12 -5 -19 -2 -23 11 -3 14 -22 21 -78 31 -41 8 -81 11 -90 8 -8 -4
-15 -1 -15 6 0 7 -30 28 -67 46 -99 48 -184 112 -255 191 -34 39 -66 70 -70
70 -14 0 81 -117 134 -165 100 -90 226 -159 333 -184 125 -30 156 -33 255 -28
249 14 471 130 613 322 144 193 198 427 151 653 -14 67 -41 149 -43 130z"/>
<path d="M1388 1090 c3 -32 0 -55 -8 -65 -10 -13 -9 -15 11 -15 22 0 24 4 22
42 -1 24 -2 53 -2 66 -1 12 -7 22 -15 22 -10 0 -12 -12 -8 -50z"/>
<path d="M1038 1118 c18 -18 15 -108 -5 -130 -13 -15 -14 -18 -2 -18 28 0 39
24 39 86 0 41 4 64 13 67 6 3 -4 6 -22 6 -27 1 -32 -2 -23 -11z"/>
<path d="M1699 1079 c2 -35 -1 -54 -10 -60 -9 -6 4 -8 36 -7 42 3 52 7 65 31
13 24 13 30 0 55 -12 21 -24 28 -55 30 l-40 3 4 -52z m59 29 c23 -23 9 -88
-20 -88 -4 0 -8 23 -8 50 0 51 6 60 28 38z"/>
<path d="M1595 1107 c-5 -7 -8 -30 -5 -52 4 -32 9 -41 28 -43 21 -3 32 13 13
20 -17 6 -23 48 -7 54 13 5 13 8 1 20 -16 17 -17 17 -30 1z"/>
<path d="M1924 1110 c-5 -5 -8 -30 -6 -55 3 -38 7 -45 25 -45 16 0 18 3 9 12
-15 15 -16 50 -2 64 8 8 7 14 -3 22 -8 7 -17 8 -23 2z"/>
<path d="M1106 1091 c-4 -5 -2 -12 3 -15 5 -4 12 -2 16 4 11 17 24 1 22 -27
-1 -15 -9 -29 -16 -31 -10 -3 -12 1 -8 17 3 14 1 21 -10 21 -9 0 -13 -8 -11
-22 2 -19 10 -23 39 -25 l35 -2 -5 42 c-5 36 -10 42 -33 45 -14 2 -29 -1 -32
-7z"/>
<path d="M1195 1054 c0 -39 3 -44 23 -44 19 0 21 3 11 19 -13 20 -6 51 11 51
5 0 10 -16 10 -35 0 -28 4 -35 19 -35 13 0 18 6 16 18 -15 73 -13 71 -53 70
l-37 0 0 -44z"/>
<path d="M1303 1084 c-4 -11 2 -23 18 -35 13 -10 21 -21 17 -25 -4 -4 -14 -1
-23 6 -12 10 -15 10 -15 -4 0 -10 9 -16 25 -16 36 0 44 28 15 52 -12 10 -20
21 -17 25 4 3 13 0 22 -7 12 -10 15 -10 15 4 0 22 -49 22 -57 0z"/>
<path d="M1428 1088 c7 -7 12 -27 12 -45 0 -22 5 -33 15 -33 11 0 13 8 9 33
-9 51 -12 57 -31 57 -13 0 -14 -3 -5 -12z"/>
<path d="M1496 1091 c-4 -5 -2 -12 3 -15 5 -4 12 -2 16 4 11 17 24 1 22 -27
-1 -15 -9 -29 -16 -31 -10 -3 -12 1 -8 17 3 14 1 21 -10 21 -9 0 -13 -8 -11
-22 2 -19 10 -23 39 -25 l35 -2 -5 42 c-5 36 -10 42 -33 45 -14 2 -29 -1 -32
-7z"/>
<path d="M1827 1094 c-13 -13 5 -25 19 -13 12 9 14 5 14 -25 0 -20 -5 -36 -11
-36 -6 0 -9 9 -6 20 6 23 -18 29 -26 7 -9 -23 6 -35 44 -35 29 0 36 4 33 17
-13 67 -15 71 -38 71 -12 0 -26 -3 -29 -6z"/>
<path d="M1986 1091 c-4 -5 -2 -12 3 -15 5 -4 12 -2 16 4 10 17 21 3 20 -26 0
-15 -6 -29 -14 -32 -10 -3 -12 1 -8 17 3 14 1 21 -10 21 -9 0 -13 -8 -11 -22
2 -19 10 -23 39 -25 l35 -2 -5 42 c-5 36 -10 42 -33 45 -14 2 -29 -1 -32 -7z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
